import React from "react"
import TutorialLayout from "../../components/TutorialLayout"
import IMG from "../../images/Finalizer.svg"
import STEP_THREE_CONTENT from "../../images/FinalizerContent.svg"

const Second = () => (
  <TutorialLayout
    imageSrc={IMG}
    step="5"
    buttonText="Finalizează ->"
    title="Acordă permisiunile necesare"
    description={
      <p>
        După ce ai selectat butonul <b>Mai multe detalii,</b> se va deschide o
        nouă fereastră în care vi se va cere permisiunea de a deschide insula
        ecologică folosind aplicația mobilă.
      </p>
    }
    buttonLink="/salut/finish"
  >
    <img
      src={STEP_THREE_CONTENT}
      alt="App Store"
      style={{ width: "100%", marginBottom: 16 }}
    />
    <span
      style={{
        fontSize: 16,
        color: "#2E3338",
        lineHeight: "24px",
        marginTop: 16,
      }}
    >
      După ce ai acordat permisiunea, va apărea următoarea pictogramă, care te
      va ghida să apropii telefonul de container, în zona unde se află
      cititorul.
    </span>
  </TutorialLayout>
)

export default Second
